
// base color
$main-color:#F36815;
$main-gray:#707070;
$main-tab-active: #A84527;

*,*:before,
*:after {
  margin:0;
  padding:0;
  box-sizing: border-box;
}
@import './style.css';

//html{
//  font-size:13.02083333333vw;
//  max-font-size: 20px;
//}
// 100/7.68vw = 1rem
@media screen and (max-width:768px){
  html{
    font-size:13.02083333333vw
  }
}
@media screen and (min-width:769px) and (max-width:800px){
  html{
    font-size:12vw;
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}

@media screen and (min-width:801px) and (max-width:1024px){
  html{
    font-size:11vw;
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}
@media screen and (min-width:1025px) and (max-width:1280px){
  html{
    font-size:7vw;
    .homeList .homeList_l{
      width:24%;
    }
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}
@media screen and (min-width:1281px) and (max-width:1500px){
  html{
    font-size:6vw;
    .homeList .homeList_l{
      width:24%;
    }
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}
@media screen and (min-width:1501px) and (max-width:1920px){
  html{
    font-size:5vw;
    .homeList .homeList_l{
      width:24%;
    }
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}
@media screen and (min-width:1921px){
  html{
    font-size:4.5vw;
    .homeList .homeList_l{
      width:24%;
    }
    .keyboardBox .keyboardBox_list:nth-of-type(3),.keyboardBox .keyboardBox_list:nth-of-type(4){
      justify-content: center;
    }
  }
}

// 768rem = 100vw

//html{
//  font-size:13.02083333333vw
//}
body {
  margin: 0;
  -webkit-text-size-adjust: none;
  font-family:'microsoft yahei';
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  //font-size:.8rem;
  background: #ffffff !important;
  /*
  768px = 100vw
  100px = 100 /7.68vw;
  100px = 13.02083333333vw
  */
}


.am-tabs-pane-wrap{
  overflow-x:hidden;
}

body,h1,h2,h3,h4,p,dl,dd,ul,li{margin:0;}
img{
  display: block;
}
.App{
  min-width:768px;
}
.container{
  padding:0.14rem 0.32rem 0;
  position:relative;
  top:1.2rem;
}
.containerPark{
  padding:0.14rem 0 0;
  position: relative;
  top: 1.2rem;
  .publicBack{
    margin-bottom:0 !important;
    margin-left:0.4rem ;
  }
}
.containerDine{
  position:relative;
  top:1.3rem;
  .publicBack{
    margin-bottom:0.1rem !important;
    margin-left:0.35rem ;
  }
}
.infoContainer{
  height:calc(100vh - 2.48rem);
}


/* 公共header信息 */
.header{
  position:fixed;
  left:0;
  top:0;
  z-index:8;
  width:100vw;
  height: 1.2rem;
  color:#ffffff;
  background:linear-gradient(to right, #F16615,#850222);
  font-size:.16rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:0 0.34rem;
}
.headerCenter{
  flex: 1;
  margin:0 0.3rem;
}
.headerLeft .time{
  font-size:.30rem;
  font-weight: bold;
  margin-bottom:0.02rem;
}
.headerRight img{
  width:1.05rem;
}

/*公共tab样式颜色*/
.am-tabs-default-bar-tab-active{
  color:$main-color !important;
}

.publicBack{
  position:relative;
  left:-0.15rem;
  display: flex;
  align-items: center;
  font-size:0.18rem;
  font-weight: bold;
  color: $main-gray;
  text-transform: uppercase;
  //padding-left:0.15rem;
  //padding-top:0.15rem;
  margin-bottom:0.3rem;
  .am-icon{
    width:0.35rem;
    height:0.35rem
  }
}

/* 首页轮播样式修改 */
.swiperHomeStyle{
  width:100%;
  //height:3.1rem;
  border-radius:0.2rem;
  //margin-bottom:0.25rem;
  .swiper-container{

  }
  .swiper-slide img{
    width:100%;
    height:100%;
  }
  .swiper-pagination-bullet{
    width:0.12rem !important;
    height:0.12rem !important;
    background:#ffffff !important;
    opacity: 1 !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
    margin:0 0.05rem !important;
  }
  .swiper-pagination-bullet-active{
    background:$main-color !important;
  }
  .swiper-button-prev, .swiper-button-next{
    width:0.5rem !important;
    height:0.5rem !important;
    background:#ffffff !important;
    border-radius:50% !important;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    color:$main-color !important;
    font-size:0.25rem !important;
    font-weight: bold !important;
  }
}



/* 公共底部tabbar样式 */
.am-tab-bar-bar{
  height:0.91rem !important;
  border-top:none !important;
  justify-content: center !important;
}
.am-tab-bar-bar::before{
  height: 0px !important;
}
.am-tabs-tab-bar-wrap{
  height:1.28rem !important;
  padding: 0.1rem 0.22rem 0.32rem;
}
.am-tab-bar-bar .am-tab-bar-tab-title{
  font-size:0.15rem !important;
}
.am-tab-bar-bar .am-tab-bar-tab{
  flex: inherit !important;
  width:0.88rem !important;
  height:0.88rem;
  //border: 0.02rem solid $main-color !important;
  //border-radius: 0.15rem !important;
  margin:0 0.08rem !important;
}




.homeList{
  width:100%;
  //padding-top:0.25rem;
  //padding-left:0.26rem;
  //padding-right:0.26rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homeList .homeList_l{
  width:49%;
  //width:3.45rem;
  //height:1.52rem;
  //float: left;
  position:relative;
  margin:0 0 0.15rem;
  border-radius:0.2rem;
  overflow:hidden;
}
.homeList .homeList_l:nth-child(2n){
  margin-right: 0;
}
//.homeList .homeList_l .homeList_l_img{
//  width:100%;
//  height:100%;
//}
.homeList .homeList_l .homeList_l_img img{
  width:100%;
  //height:100%;
  //height:1.55rem;
  display: block;
}
.homeList .homeList_l .homeList_l_text{
  position: absolute;
  left:0;
  bottom:0;
  line-height:0.5rem;
  background:$main-color;
  color:#ffffff;
  border-top-right-radius: 0.2rem;
  padding:0 0.18rem;
  font-size:0.18rem;
}


.searchBox{
  position:relative;
  padding:0.25rem 0;
  display: block;
}
.searchStyle{
  width:100%;
  height:0.64rem;
  background:#D6D6D6;
  border:0.03rem solid #D6D6D6;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  padding:0 0.1rem;
  &:active{
    border:0.03rem solid $main-color;
    .searchStyle_say{
      //border:0.04rem solid #F2AC82;
      background:url('../../assets/images/searchSay.png') no-repeat center center $main-color !important;
    }
  }
}
.searchStyle_icon{
  width:0.65rem;
  height:0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchStyle_icon img{
  width:0.23rem;
  height:0.23rem;
}
.searchStyle_input{
  flex: 1;
  background-color:transparent !important;
  color:#636363 !important;
  padding-left:0 !important;
  height:0.44rem !important;
  .am-list-line{
    border-bottom:none !important;
  }
  .am-input-control input{
    font-size:0.23rem !important;
    color:$main-gray !important;
    &::placeholder{
      color:$main-gray !important;
    }
  }
}
.searchStyle_say{
  width:0.55rem;
  height:0.55rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border:0.04rem solid #D6D6D6;
  background:url('../../assets/images/searchSay_s.png') no-repeat center center;
  background-size:30%;
  &:active{
    border:0.04rem solid #F2AC82;
    background:url('../../assets/images/searchSay.png') no-repeat center center $main-color !important;
  }
}


/* 键盘 */
.keyboard{
  //width:100%;
  //height:2.55rem;
  //border:0.03rem solid $main-gray;
  //border-radius: 0.2rem;
  position:relative;
  width:100%;
  top:0.15rem;
  //height:2.8rem;
  //margin-top:0.16rem;
  //.close{
  //  position:absolute;
  //  right:-0.03rem;
  //  top:-0.03rem;
  //  background: $main-color;
  //  width:0.6rem;
  //  height:0.6rem;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  border-top-right-radius: 0.2rem;
  //  border-bottom-left-radius: 0.2rem;
  //  span{
  //    display: block;
  //    font-size:0.28rem;
  //    color:#ffffff;
  //  }
  //}
}

.keyboardBox{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction:column;
  .keyboardBox_list{
    display: flex;
    align-items: center;
    &:nth-of-type(1){
      justify-content: center;
    }
    &:nth-of-type(2){
      justify-content: center;
    }
    &:nth-of-type(3){
      justify-content: flex-end;
    }
    &:nth-of-type(4){
      align-items: center;
      justify-content: space-between;
    }
  }

}
.keyboard_close{
  position:absolute;
  right:-0.03rem;
  top:-0.03rem;
  background: $main-color;
  width:0.6rem;
  height:0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  cursor:pointer;
  span{
    display: block;
    font-size:0.28rem;
    color:#ffffff;
  }
}


/*商家店铺列表*/
.shop_container{
  padding:0.14rem 0.32rem 0;
  height:calc( 100vh - 2.55rem);
  display: flex;
  flex-direction: column;
  position:relative;;
  top:1.2rem;
  overflow:hidden;
}
.shop{
  flex: 1;
  width:100%;

  background:#d6d6d6;
  //height:3.53rem;
  border-radius:0.2rem;
  padding:0.10rem 0 0.1rem 0.16rem;
  overflow-y:scroll;
  overflow-x:hidden;
}
.shopBox{
  flex: 1;
  //height:3.35rem;
  //overflow-y:scroll;
  //overflow-x:hidden;
}
.shopList{
  position:relative;
  float:left;
  width:1.55rem;
  height:1.55rem;
  background: #ffffff;
  border-radius: 0.1rem;
  box-shadow:0 0.05rem 0.05rem rgba(0,0,0,0.2);
  border:0.02rem solid #B8B8B8;
  margin-right:0.16rem;
  margin-bottom:0.14rem;
  cursor:pointer;
}
//.shopList:nth-child(4n){
//  margin-right:0;
//}
.shopList_t{
  display: flex;
  align-items: center;
  justify-content: center;
  height:0.75rem;
  border-bottom:0.019rem solid #B8B8B8;
  padding:0.13rem 0;
}
.shopList_t img{
  height:100%;
}
.shopList_b{
  padding:0.12rem;
  font-size:0.18rem;
}
.shopList_floor{
  position:absolute;
  right:0.13rem;
  bottom:0.1rem;
  color:#000000;
  font-size:0.2rem;
  font-weight: bold;
}


.tabBox .am-tabs-default-bar-tab{
  font-size:0.17rem !important;
  text-transform: uppercase;
  color:$main-gray;
  line-height:0.43rem;
  height:0.43rem;
  &:after{
    height: 0 !important;
  }
}
.tabBox .am-tabs-default-bar-top .am-tabs-default-bar-tab{
  border-bottom: none !important;
  cursor:pointer;
}
.tabBox .am-tabs-default-bar-underline{
  display: none;
  border:0.02rem solid $main-color !important;
  border-radius:0.05rem;
}
.tabBox .am-tab-bar-bar{
  height:inherit !important;
}
.tabBox .am-tabs-tab-bar-wrap{
  height:0.6rem !important;
  padding:0.1rem 0 0;
}
.tabBox .am-tabs-default-bar-tab-active{
  color:$main-color !important;
  font-weight: bold;
  position:relative;
  &:first-child{
    &:after{
      content: '';
      position:absolute;
      transform-origin: 50% 100%;
      transform: scaleY(0.5);
      display: block;
      z-index: 1;
      right: auto;
      bottom: 0;
      top:0.3rem !important;
      background:$main-color !important;
      left:50% !important;
      width:1.1rem !important;
      margin-left:-0.55rem !important;
      height:0.06rem !important;
      border-radius:0.05rem !important;
    }
  }
  &:nth-child(2){
    &:after{
      content: '';
      position:absolute;
      transform-origin: 50% 100%;
      transform: scaleY(0.5);
      display: block;
      z-index: 1;
      right: auto;
      bottom: 0;
      background:$main-color !important;
      top:0.3rem !important;
      left:50% !important;
      width:1.9rem !important;
      margin-left:-0.95rem !important;
      height:0.06rem !important;
      border-radius:0.1rem !important;
    }
  }
  &:nth-child(3){
    &:after{
      content: '';
      position:absolute;
      transform-origin: 50% 100%;
      transform: scaleY(0.5);
      display: block;
      z-index: 1;
      right: auto;
      bottom: 0;
      top:0.3rem !important;
      background:$main-color !important;
      left:50% !important;
      width:0.5rem !important;
      margin-left:-0.25rem !important;
      height:0.06rem !important;
      border-radius:0.1rem !important;
    }
  }
}

.levelBox{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-warp:warp; /*CSS3样式，支持webkit，ms*/
  -ms-flex-wrap: wrap;/*IE9以及一下不支持*/
  -webkit-flex-wrap: wrap;
  color:#707070;
}
.levelBox .levelBox_list{
  width:0.65rem;
  height:0.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border:0.02rem solid #707070;
  border-radius:0.06rem;
  margin:0 0.08rem 0.16rem;
  font-weight: bold;
  font-size:0.3rem;
  cursor:pointer;
}
.levelBox .levelBox_list:first-child{
  font-size:0.12rem;
  padding-left:0.07rem;
}
//.levelBox .levelBox_list:last-child{
//  font-size:0.12rem !important;
//  padding-left:0.05rem;
//}
.levelBox .levelBox_active{
  background:$main-color;
  color:#ffffff;
  border:0.02rem solid $main-color;
}


/* 弹窗详情样式 */
.detailModal{
  width:5.4rem !important;
  min-height:4rem;
  position:relative;
}
.detailModal .am-modal-content{
  border-radius: 0.35rem !important;
  border:0.01rem solid $main-color;
  padding-top:0 !important;
  overflow:inherit !important;
}
.detailModal .am-modal-content .am-modal-body{
  padding:0 !important;
}
.detailModal .detailModal_img{
  border-top-right-radius: 0.35rem;
  border-top-left-radius: 0.35rem;
  overflow:hidden;
}
.detailModal .detailModal_img img{
  width:100%;
  height:2.8rem;
}
.detailModal .detailModal_top{
  margin-top:-0.45rem;
  padding:0 0.25rem;
  display: flex;
  align-items: flex-end;
  font-size:0.25rem;
  text-align: left;
  line-height: 1;
  margin-bottom:0.25rem;
}
.detailModal .detailModal_top img{
  width:1.48rem;
  //height:1.5rem;
  margin-right:0.2rem;
}
.detailModal .detailModal_top .name{
  color:#000000;
  font-weight: bold;
  margin-bottom:0.07rem;
}
.detailModal .detailModal_top .time{
  color:$main-color;
}
.detailModal .detailModal_nr{
  padding:0 0.25rem 0.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size:0.14rem;
  color:#000000;
  .lf{
    text-align: left;
    text-transform: uppercase;
    width: 1.77rem;
    word-break: break-word;
    .lf_l{
      margin-bottom:0.1rem;
      span{
        display: block;
        color:$main-color;
      }
    }
  }
  .rg{
    width:2.8rem;
    text-align: left;
  }
}
.detailModal .detailModal_btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:0 0.2rem;
  height:0.46rem;
  line-height: 0.46rem;
  text-align: center;
  border:0.03rem solid $main-gray;
  border-radius:5rem;
  margin:0 auto 0.38rem;
  font-size:0.21rem;
  font-weight: bold;
  text-transform: uppercase;
  color:$main-gray;
  cursor:pointer;
}
.detailModal .close{
  position:absolute;
  right:-0.15rem;
  top:-0.15rem;
  width:0.45rem;
  height:0.45rem;
  background: $main-color;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.detailModal .close .am-icon-md{
  width:0.36rem;
  height:0.36rem;
  color:#FFFFFF;
  font-weight: bold;
}
.detailModal .detailModal_rewards{
  padding:0.25rem 0.34rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color:#000000;
  .lf{
    width:1.65rem;
    text-align: left;
    font-size:0.16rem;
    .tit{
      font-size:0.22rem;
      font-weight: bold;
      text-transform: uppercase;
      color:#000000;
      line-height: 1.3;
    }
    .te{
      color:$main-color
    }
  }
  .rg{
    width:2.8rem;
    text-align: left;
    font-size:0.16rem;
    .am-tabs-default-bar-top .am-tabs-default-bar-content{
      //width:2rem;
    }
    .am-tabs-default-bar-underline{
      display: none;
    }
    .am-tabs-default-bar-tab{
      position: relative;
      //padding:0 0.15rem;
      width:0.7rem !important;
      margin-right:0.15rem;
      line-height: 0.25rem;
      height:0.25rem;
      justify-content: flex-start;
      font-weight: bold;
      &:first-child{
        &:before{
          width:0.01rem;
          background: #000000;
          height:0.15rem;
          content: "";
          position: absolute;
          right:0;
          top:0.05rem;
        }
      }
    }
    .am-tabs-tab-bar-wrap{
      height: auto !important;
      padding:0 !important;
    }
  }
}

.mapModal{
  width:4rem !important;
  position:relative;
  .am-modal-content{
    overflow:inherit !important;
  }
  .close{
    position:absolute;
    right:-0.15rem;
    top:-0.15rem;
    width:0.45rem;
    height:0.45rem;
    background: $main-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    .am-icon-md{
      width:0.36rem;
      height:0.36rem;
      color:#FFFFFF;
      font-weight: bold;
    }
  }
  img{
    width:100%
  }
}

/* 分类 */
.category{
  border-radius: 0.2rem;
  border:0.02rem solid $main-color;
  display: flex;
  align-items: center;
  flex-warp:warp; /*CSS3样式，支持webkit，ms*/
  -ms-flex-wrap: wrap;/*IE9以及一下不支持*/
  -webkit-flex-wrap: wrap;
  text-align: center;
  padding:0.12rem 0 0 0;
  margin-top:0.16rem;
  font-size:0.16rem;
  .category_active{
    .category_icon{
      border:0.01rem solid $main-color !important;
      background:$main-color;
      .icon{
        color:#ffffff !important;
      }
    }
  }

}
.category .category_list{
  width:1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin:0 0.08rem 0.20rem;
  .category_name{
    height:0.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color:$main-gray;
  }
  .category_icon{
    width:0.65rem;
    height:0.65rem;
    border:0.01rem solid $main-gray;
    margin-bottom:0.07rem;
    border-radius: 0.07rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    img{
      width:95%;
      height:95%;
    }
    .icon{
      font-size:0.5rem;
      color:$main-gray;
    }
  }
  //&:active{
  //  .category_icon{
  //    border:0.01rem solid $main-color;
  //    background:$main-color;
  //    .icon{
  //      color:#ffffff;
  //    }
  //  }
  //}
}

.shopBot{
  flex: 1;
}
.shopFen{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:0.1rem;
  padding-top:0.05rem;
  .eve{
    width:calc(100vw/3);
    text-align: center;
    text-transform: uppercase;
    line-height:0.5rem;
    font-size:0.18rem;
    color:$main-gray;
  }
  .active{
    color:$main-color;
    font-weight: bold;
    span{
      position:relative;
      &:before{
        content:'';
        position:absolute;
        left:0;
        bottom:-0.05rem;
        width:100%;
        height:0.04rem;
        background:$main-color;
        border-radius:0.04rem;
      }
    }
  }
}
.shopSe{
  min-height:3.5rem;
}
.shopSdg{flex:1;}


/* help页面 */
.helpBox {
  .helpBox_title{
    font-size:0.26rem;
    margin-bottom:0.15rem;
    span{
      display: block;
      font-size:0.52rem;
      font-weight: bold;
      margin-bottom:0.15rem;
    }
  }
  .helpBox_img{
    width:100%;
    margin-bottom:0.24rem;
  }
  .helpBox_nr{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size:0.16rem;
    color:#707070;
    .helpBox_nr_l{
      width:60%;
      .l_tit{
        font-size:0.24rem;
        font-weight: bold;
        margin-bottom:0.15rem;
        color:#000000;
      }
      .l_nr{
        font-size:0.17rem;
        p{
          margin-bottom:0.2rem;
        }
      }
    }
    .helpBox_nr_r{
      width:40%;
      .r_note{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom:0.45rem;
        .r_note_tit{
          font-size:0.19rem;
          margin-right:0.1rem;
        }
      }
      .r_call{
        width:100%;
        height:0.6rem;
        display:flex;
        align-items:center;
        justify-content:center;
        font-weight: bold;
        border-radius:0.5rem;
        border:0.03rem solid $main-gray;
        color:$main-gray;
        &:active{
          background:#707070;
          color:#ffffff;
          span{
            background:url("../../assets/images/phone-call_hover.png") no-repeat center center;
            background-size: 100%;
          }
        }
        span{
          display: block;
          width:0.29rem;
          height:0.29rem;
          margin-right:0.16rem;
          background:url("../../assets/images/phone-call.png") no-repeat center center;
          background-size: 100%;
        }
      }
    }
  }
}


/* rewards */
.rewardsBox{
  padding-top:0.15rem;
  .topImg{
    //height:3rem;
    position:relative;
    //color:#ffffff;
    font-size: 0.5rem;
    //font-weight: bold;
    //padding-left:0.15rem;
    //display: flex;
    //align-items: center;
    //margin-bottom:0.25rem;
    img{
      //position:absolute;
      //left:0;
      //top:0;
      width:100%;
      height:100%;
      z-index:-1
    }
    .nr{
      position:absolute;
      left:0.15rem;
      top:0;
      width:100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .rewardsSwiper{
    position:relative;
    width:100%;
    overflow:hidden;
    padding:0 4%;
  }
  .rewardsNo{
    text-align: center;
    font-size:0.24rem;
    height:3rem;
    line-height:3rem;
  }
  .swiper-button-prev:after, .swiper-button-next:after{
    color:$main-color !important;
    font-size:0.25rem !important;
    font-weight: bold !important;
  }
  .swiper-button-prev{
    left:-0.05rem !important;
  }
  .swiper-button-next{
    right:-0.05rem !important;
  }
  .swiperStyle{
    //height:3.1rem;
    //margin-top:0.25rem;
    //width:6.54rem;
    position: inherit !important;
    overflow: inherit !important;
    .swiper-slide{
      //width:1.98rem !important;
      //margin:0 0.1rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .SwiList{
      width:90%;
      //height:3.1rem;
      border:0.02rem solid #707070;
      border-radius:0.13rem;
      padding:0.08rem;
      img{
        width:100%;
        //height:1.7rem;
      }
      .SwiList_tit{
        font-size:0.2rem;
        color:#707070;
        font-weight: bold;
        padding:0.2rem 0;
      }
      .SwiList_btn{
        border-radius: 0.4rem;
        height:0.45rem;
        background: $main-color;
        text-align: center;
        line-height: 0.45rem;
        color:#ffffff;
        font-size:0.14rem;
      }
    }
  }
}



/* map */
.map{
  width:100%;
  height:4.6rem;
  position:relative;
  overflow:hidden;
  border-radius: 0.17rem;
  .map_mask{
    position:absolute;
    left:0;
    top:0;
    z-index: 4;
    width:100%;
    height:4.6rem;
    background:rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size:0.24rem;
    color:#ffffff;
    span{
      font-size:1.1rem;
      color:#ffffff;
      display: block;
      margin-bottom:0.15rem;
    }
  }
  .mapBox{
    height:4.6rem;
    border:0.03rem solid $main-gray;
    border-radius: 0.17rem;
    overflow:hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .map_floor{
    position: absolute;
    left:0;
    top:0;
    z-index:999;
    background: $main-color;
    color:#ffffff;
    border-top-left-radius: 0.17rem;
    border-bottom-right-radius: 0.17rem;
    width:0.97rem;
    height:0.97rem;
    text-align: center;
    line-height: 0.97rem;
    font-size:0.64rem;

  }
  .react-draggable{
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:4.6rem !important;
  }
  .map_nr{
    position:relative;
    width:6rem;
    height:4rem;
    //background:url("../../assets/images/floor/Floor_plan.png") no-repeat center center;
    background-repeat: no-repeat;
    background-size:auto 100%;
    background-position: center center;
    .location{
      position:absolute;
      left:0.2rem;
      top:1.2rem;
      text-transform: uppercase;
      text-align: center;
      color:$main-color;
      font-size:0.12rem;
      font-weight: bold;
      line-height: 0.2rem;
      span{
        font-size:0.25rem;
      }
    }
    .eve{
      display: flex;
      align-items: center;
      justify-content: center;
      color:$main-gray;
      font-size:0.24rem;
      width:0.32rem;
      height:0.32rem;
      border-radius:0.05rem;
      border:0.01rem solid $main-gray;
      position:absolute;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-color:#ffffff;
    }
    .active{
      border:0.01rem solid $main-color !important;
      color:#ffffff !important;
      //background: $main-color;
    }
    .baby{
      position:absolute;
      left:1.3rem;
      top:1.2rem;
    }
    .toilet{
      position:absolute;
      left:2.4rem;
      top: 0.4rem;
    }
    .wheelchair{
      position:absolute;
      left:1.4rem;
      top:1.9rem;
    }
    .circle{
      position:absolute;
      left:2.1rem;
      bottom:1.2rem;
    }
    .elevator{
      position:absolute;
      left:2.1rem;
      bottom:0.4rem;
    }
    .escalator{
      position:absolute;
      right:1rem;
      top:1.2rem;
    }
    .dollar{
      position:absolute;
      right:0.2rem;
      top:0.4rem;
    }
    .taxi{
      position:absolute;
      right:-0.5rem;
      top:1.2rem;
    }
  }

  .map_nr_pc{
    height:4.2rem !important;
    width:6.3rem !important;
  }
}



/* info */
.InfoBox{
  width:100%;
  height:calc(100vh - 3.7rem);
  position:relative;
  overflow-x:hidden;
  overflow-y:scroll;
  .am-tabs-pane-wrap-active{
    margin-bottom:0.15rem;
  }
  .am-tabs-tab-bar-wrap{
    height:0.72rem !important;
    background:$main-color;
    border-radius:0.12rem;
    padding:0 !important;
    overflow:hidden;
    .am-tabs-default-bar-tab{
      background:$main-color;
      color:#ffffff;
      height:0.72rem;
      line-height:0.72rem;
      text-transform:uppercase;
      font-size:0.16rem;
    }
    .am-tabs-default-bar-tab-active{
      color:#ffffff !important;
      background:$main-tab-active
    }
    .am-tabs-default-bar-underline{
      display: none;
    }
  }
  .tab{
    height:0.72rem;
    position:fixed;
    left:0.33rem;
    right:0.33rem;
    bottom:1.5rem;
    background:$main-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:0.12rem;
    overflow:hidden;
    .tab_list{
      height:0.72rem;
      line-height:0.72rem;
      width:25%;
      text-align: center;
      color:#ffffff;
      text-transform:uppercase;
      font-size:0.16rem;
    }
    .active{
      background:$main-tab-active !important;
    }
  }
}
.infoList{
  display: flex;
  flex-direction: column;
  .infoList_tit{
    font-size:0.54rem;
    font-weight: bold;
    color:#000000;
    line-height: 1;
    margin-bottom:0.15rem;
  }
  .infoList_nr{
    flex:1;
    height:4.65rem;
    //overflow-x:hidden;
    //overflow-y:scroll;
    margin-bottom:0.15rem;
    .about_img{
      width:100%;
      margin-bottom:0.15rem;
    }
    .infoList_nr_tit{
      font-size:0.2rem;
      line-height: 1;
      margin-bottom:0.15rem;
    }
    .infoList_nr_nr{
      p{
        line-height:0.22rem;
        font-size:0.17rem;
        margin-bottom:0.2rem;
        color:$main-gray
      }
    }
  }

  .parking_tit{
    padding:0.3rem 0.33rem ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .lf{
      font-size:0.54rem;
      line-height: 1;
      font-weight: bold;
    }
    .rg{
      font-size:0.2rem;
      line-height: 0.3rem;
    }
  }

  .pinkingSwiper{
    .swiper-container{
      padding-left:0.33rem;
      .swiper-slide{
        width:3.43rem !important;
        margin-right:0.2rem !important;
      }
    }
    .PikList{
      width:3.43rem;
      height:4.3rem;
      border:0.03rem solid $main-color;
      border-radius:0.10rem;
      padding:0.3rem;
      .img1{
        width:0.45rem;
      }
      .img2{
        width:0.6rem;
      }
      .img3{
        width:0.45rem;
      }
      .img4{
        width:0.36rem;
      }
      .title{
        text-transform: uppercase;
        color:$main-color;
        font-weight: bold;
        font-size:0.28rem;
        padding:0.1rem 0 0.2rem;
      }
      .xnr{
        margin-bottom:0.2rem;
        .xnr_tit{
          color:$main-color;
          font-size:0.2rem;
          font-weight: bold;
          margin-bottom:0.05rem;
          line-height:0.27rem;
        }
        .xnr_s{
          font-size:0.15rem;
          line-height:0.21rem;
        }
      }
    }
  }
}


.faqTitle{
  font-weight: bold;
  font-size:0.25rem;
  padding:0 0 0.15rem;
}

.accordionStyle{
  padding-top:0.2rem;
  .am-accordion-item{
    border:0.03rem solid $main-gray;
    border-radius:0.2rem;
    overflow:hidden;
    margin-bottom:0.2rem;
    font-weight: bold;
    &:active{
      border:0.03rem solid $main-color;
      background: $main-color !important;
      .am-accordion-header{
        background: $main-color !important;
        color:#ffffff !important;
        .arrow{
          background-image:url("../../assets/images/faqIcon1_1.png");
        }
      }
    }
    .am-accordion-header{
      height:0.71rem !important;
      line-height: 0.71rem !important;
      color:$main-gray !important;
      font-size:0.2rem !important;
      padding:0 0.4rem !important;
      border-bottom: none !important;
      &:active{
      }
      .arrow{
        background-image:url("../../assets/images/faqIcon1.png");
        top:0.28rem !important;
        right:0.28rem !important;
        width:0.13rem !important;
        height:0.13rem !important;
        transform:rotate(0deg) !important;
      }
    }
    .am-accordion-content-box{
      padding:0 0.4rem 0.3rem;
      .faq_list{
        color:$main-gray;
        font-size:0.2rem;
        p{
          margin-bottom:0.2rem
        }
      }
    }
  }
  .am-accordion-item-active{
    .arrow {
      background-image: url("../../assets/images/faqIcon2.png") !important;
    }
  }
}


.floorTabs{
  padding-top:0.32rem;
  .am-tabs-vertical .am-tabs-pane-wrap{
    height: auto !important;
  }
  .am-tabs-vertical .am-tabs-pane-wrap-inactive{
    display: none;
  }
  .am-tabs-tab-bar-wrap{
    padding:0 !important;
    width:1.6rem !important;
    height:auto !important;
    .am-tabs-default-bar-tab{
      height:0.48rem !important;
      line-height: 0.48rem !important;
      font-size:0.24rem;
      font-weight: bold;
      justify-content: flex-end;
      padding-right:0.15rem;
      color:$main-gray;
      border-bottom:0.01rem solid $main-gray;
      border-right:none !important;
    }
    .am-tabs-default-bar-underline{
      display: none;
    }
    .am-tabs-default-bar-tab-active{
      background:$main-color;
      color:#ffffff !important;
    }
  }

  .am-tabs-default-bar-content{
    .am-tabs-default-bar-tab{
      &:nth-last-child(2){
        border-bottom: none !important;
      }
    }
  }

  .am-tabs-content-wrap{
    padding-left:0.35rem;
    .floorTabs_list{
      display: flex;
      align-items: center;
      //justify-content: space-between;
      flex-warp:warp; /*CSS3样式，支持webkit，ms*/
      -ms-flex-wrap: wrap;/*IE9以及一下不支持*/
      -webkit-flex-wrap: wrap;
      text-align: center;
      .ic{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:0.82rem;
        margin:0 0.21rem 0.24rem;
        font-size:0.16rem;
        .ic_t{
          width:0.71rem;
          height:0.71rem;
          border:0.02rem solid $main-gray;
          border-radius:0.08rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom:0.03rem;
          overflow:hidden;
          .icon{
            font-size:0.45rem;
            color:$main-gray;
          }
        }
        .ic_b{
          height:0.37rem;
          display: flex;
          align-items: center;
          color:$main-gray;
        }
      }
      .ic_active{
        .ic_t{
          border:0.02rem solid $main-color;
          background:$main-color;
          .icon{
            color:#ffffff;
          }
        }
        .ic_b{
          color:$main-color;
        }
      }
    }
  }
}


/* what‘on 页面*/
.rewardsBox_banner{
  position:relative;
  .swiperWhatStyle{
    width:100%;
    //height:3rem;
    border-radius:0.2rem;
    //margin-bottom:0.25rem;
    .swiper-container{

    }
    .swiper-slide img{
      width:100%;
      height:100%;
      display: block;
    }
    .swiper-pagination-bullet{
      width:0.12rem !important;
      height:0.12rem !important;
      background:#ffffff !important;
      opacity: 1 !important;
    }
    .swiper-pagination-bullet{
      margin:0 0.04rem !important;
    }
    .swiper-pagination-bullets{
      bottom:0.13rem !important;
    }
    .swiper-pagination-bullet-active{
      background:$main-color !important;
    }
  }

  .swiper-banner-button-prev{
    position:absolute;
    left:0.15rem;
    bottom:0.15rem;
    width:0.15rem;
    height:0.25rem;
    background:url("../../assets/images/what_left_icon.png") no-repeat center center;
    background-size: 100%;
    z-index: 1;
    cursor:pointer;
  }
  .swiper-banner-button-next{
    position:absolute;
    right:0.15rem;
    bottom:0.15rem;
    width:0.15rem;
    height:0.25rem;
    background:url("../../assets/images/what_right_icon.png") no-repeat center center;
    background-size: 100%;
    z-index: 1;
    cursor:pointer;
  }
}

.whatBox{
  width: 100%;
  //padding-top:0.2rem;
  .am-tabs-pane-wrap-active {
    margin-bottom: 0.15rem;
  }

  .am-tabs-tab-bar-wrap {
    height: 0.72rem !important;
    //background: $main-color;
    border-radius: 0.12rem;
    padding: 0 !important;
    overflow: hidden;

    .am-tabs-default-bar-tab {
      background: $main-color;
      color: #ffffff;
      height: 0.72rem;
      line-height: 0.72rem;
      text-transform: uppercase;
      font-size: 0.16rem;
    }

    .am-tabs-default-bar-tab-active {
      color: #ffffff !important;
      background: $main-tab-active
    }

    .am-tabs-default-bar-underline {
      display: none;
    }
  }
}



.whatSwiper{
  position:relative;
  width:100%;
  overflow:hidden;
  .swiper-button-prev:after, .swiper-button-next:after{
    color:$main-color !important;
    font-size:0.25rem !important;
    font-weight: bold !important;
  }
  .swiper-button-prev{
    left:-0.05rem !important;
  }
  .swiper-button-next{
    right:-0.05rem !important;
  }
  .swiperStyle{
    //height:2.18rem;
    margin-top:0.25rem;
    width:94%;
    position: inherit !important;
    overflow: inherit !important;
    .swiper-slide{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .SwiEveryList{
      position:relative;
      width:94%;
      //height:2.18rem;
      //overflow:hidden;
      //border-radius:0.13rem;

      .SwiEveryList_top{
        //height:1.82rem;
        padding:0.05rem;
        border-left:0.02rem solid #707070;
        border-right:0.02rem solid #707070;
        border-top:0.02rem solid #707070;
        border-top-left-radius: 0.13rem;
        border-top-right-radius: 0.13rem;
        img{
          width:100%;
          //height:0.8rem;
        }
      }
      .SwiEveryList_tit{
        font-size:0.19rem;
        padding:0.06rem 0 0;
        text-transform: uppercase;
        line-height:0.26rem;
        height:0.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;/*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
        -webkit-line-clamp: 2;/*用来限制在一个块元素显示的文本的行数。*/
        -webkit-box-orient: vertical;
      }
      .SwiEveryList_time{
        color:$main-color;
        font-size:0.14rem;
        margin-bottom:0.15rem;
      }
      .SwiEveryList_btn{
        text-transform: uppercase;
        width:100%;
        height:0.36rem;
        background: $main-color;
        text-align: center;
        line-height: 0.36rem;
        color:#ffffff;
        font-size:0.15rem;
        border-bottom-right-radius: 0.04rem;
        border-bottom-left-radius: 0.04rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width:0.23rem;
          margin-right:0.13rem;
        }
      }
    }
  }
}
.noEvents{
  text-align: center;
  font-size:0.22rem;
  text-transform: uppercase;
  //padding:1.02rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Dine 页面*/
.dine_title{
  font-size:0.52rem;
  font-weight: bold;
  padding:0.05rem 0 0.15rem 0.25rem;
}
.dine_search{
  padding:0 0.32rem;
}
.DineSwiper{
  .swiper-container{
    padding-left:0.3rem;
  }
  .swiper-slide{
    width:3.41rem !important;
    margin-right:0.2rem;
  }
  .PikList{
    width:3.41rem;
    height:4.7rem;
    border:0.02rem solid $main-color;
    border-radius:0.15rem;
    overflow:hidden;
    padding:0.3rem;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .img1{
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
    }
    .cont{
      position:relative;
      z-index: 1;
      width:2.67rem;
      height:3.27rem;
      background:rgba(0,0,0,0.5);
      border-radius:0.12rem;
      padding:0.35rem 0.2rem 0.1rem 0.2rem;
      .cont_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:0.05rem;
      }
      .cont_center{
        height:1.48rem;
        color:#ffffff;
        line-height:0.2rem;
        font-size:0.14rem;
        margin-bottom:0.2rem;
      }
      .cont_btn{
        width:100%;
        height:0.4rem;
        line-height: 0.4rem;
        text-align: center;
        color:#ffffff;
        background: $main-color;
        border-radius: 2rem;
        font-size:0.14rem;
      }
    }
  }

}



.tabDineBox .am-tabs-default-bar-tab{
  font-size:0.17rem !important;
  text-transform: uppercase;
  &:before{
    height: 0 !important;
  }
}
.tabDineBox .am-tabs-default-bar-underline{
  display: none;
  border:0.02rem solid $main-color !important;
  border-radius:0.05rem;
}
.tabDineBox .am-tab-bar-bar{
  height:inherit !important;
}
.tabDineBox .am-tabs-tab-bar-wrap{
  height:inherit !important;
  padding:0.15rem 0 0.2rem;
}
.tabDineBox .am-tabs-default-bar-tab-active{
  color:$main-color !important;
  font-weight: bold;
  &:first-child{
    &:before{
      bottom:0 !important;
      top:inherit !important;
      background:$main-color !important;
      left:50% !important;
      width:0.6rem !important;
      margin-left:-0.3rem !important;
      height:0.06rem !important;
      border-radius:0.1rem !important;
    }
  }
  &:nth-child(2){
    &:before{
      bottom:0 !important;
      top:inherit !important;
      background:$main-color !important;
      left:50% !important;
      width:1.1rem !important;
      margin-left:-0.55rem !important;
      height:0.06rem !important;
      border-radius:0.1rem !important;
    }
  }
  &:nth-child(3){
    &:before{
      bottom:0 !important;
      top:inherit !important;
      background:$main-color !important;
      left:50% !important;
      width:1.2rem !important;
      margin-left:-0.6rem !important;
      height:0.06rem !important;
      border-radius:0.1rem !important;
    }
  }
}


.callBox{
  width:100%;
  height:4.64rem;
  background:#000000;
}
.callBox_btn{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:0.74rem;
  .btn_li{
      width:47%;
    height:0.82rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border:0.03rem solid $main-gray;
    border-radius:0.2rem;
    color:$main-gray;
    font-size:0.25rem;
    font-weight: bold;
    -moz-user-select:none;/*火狐*/
    -webkit-user-select:none;/*webkit浏览器*/
    -ms-user-select:none;/*IE10*/
    -khtml-user-select:none;/*早期浏览器*/
    user-select:none;
    .btn_li_icon{
      width:0.39rem;
      height:0.39rem;
      margin-right:0.35rem;
    }
    &:first-child{
      .btn_li_icon{
        background:url("../../assets/images/call_def1.png") no-repeat center center;
        background-size: 100%;
      }
    }
    &:last-child{
      .btn_li_icon{
        background:url("../../assets/images/call_def2.png") no-repeat center center;
        background-size: 100%;
      }
    }
    &:active{
      background:$main-color;
      color:#ffffff;
      cursor:pointer;
      border:0.03rem solid $main-color;
      &:first-child{
        .btn_li_icon{
          background:url("../../assets/images/call_def1_hover.png") no-repeat center center;
          background-size: 100%;
        }
      }
      &:last-child{
        .btn_li_icon{
          background:url("../../assets/images/call_def2_hover.png") no-repeat center center;
          background-size: 100%;
        }
      }
    }
  }
}



/* 注册 */
.registerBox{
  width:60%;
  margin:1.5rem auto 0;
  .am-list-item{
    //background:$main-gray !important;
    border:0.01rem solid $main-gray;
    border-radius:0.05rem ;
    margin-bottom:0.3rem;
    &:focus{
      border:1px solid $main-color !important;
    }
    .am-input-control{
      height:0.5rem;
      line-height:0.5rem;
    }
  }
  .am-list-item.am-input-item{
    height:0.5rem;
    padding-left:0.15rem;
  }
  .am-list-item .am-input-control input{
    font-size:0.17rem;
    height:0.5rem;
    line-height:0.5rem;
    // 修改输入框默认样式
    &:-moz-placeholder{
      color: #999999;
    }

    &:-ms-input-placeholder{
      color: #999999;
    }

    &::-webkit-input-placeholder{
      color: #999999;
    }
  }
  .title{
    text-transform: uppercase;
    font-size:0.3rem;
    text-align: center;
    font-weight: bold;
    margin-bottom:0.5rem;
  }
  .nr{
    width:100%;
    //height:3rem;
    //border:0.01rem solid $main-gray;
    //border-radius:0.2rem;
    //overflow:hidden;
    //padding:0.5rem 0.3rem;
    margin-bottom:0.7rem;
  }
  .registerBtn{
    color:#ffffff;
    background:$main-color;
    text-transform: uppercase;
    height:0.5rem !important;
    line-height:0.5rem !important;
    font-size:0.18rem;
    font-weight: bold;
  }

}




/*公共下拉选择样式*/
.select-box {
  display: flex;
  align-items: center;
  .label {
    display: inline-block;
    padding-right: 8px;
    color: #333333;
  }
  .select {
    width:100%;
    position: relative;
    .self-input {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      list-style: none;
      display: inline-block;
      height: 0.5rem;
      padding: 0.04rem 0.15rem;
      color: rgba(0, 0, 0, 1);
      font-size: 0.17rem;
      line-height: 1.5;
      background-color: #fff;
      background-image: none;
      border: 1px solid #707070;
      border-radius: 4px;
      transition: all 0.3s;
      overflow: visible;
      cursor: pointer;
    }
    .input-hover {
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      border: 1px solid rgba(24, 144, 255, 1);
    }
    .down {
      color: #dddddd;
      position: absolute;
      right: 0.15rem;
      top: 0.2rem;
      transform: rotate(0);
      transition: 0.35s;
    }
    .up {
      color: #dddddd;
      position: absolute;
      right: 0.15rem;
      top: 0.2rem;
      transform: rotate(180deg);
      transition: 0.35s;
    }
    .options {
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      top: 0.5rem;
      right: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size:0.16rem;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.35s;
      display: block;
      //opacity: 1;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
      z-index: 666;
      .item {
        line-height: 0.45rem;
        padding: 0 0.15rem;
        font-size:0.16rem;
        &:hover {
          background: #0C3EC2;
        }
      }
    }
    .options-hidden {
      display: none;
    }
    // 修改输入框默认样式
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: #999999;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: #999999;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: #999999;
    }
  }
}


