
// base color
$main-color:#F36815;
$main-gray:#707070;
$main-tab-active: #A84527;

.welcome_title{
  font-size:0.52rem;
  font-weight: bold;
  margin-bottom:0.18rem;
  text-align: center;
}

.welcome_cont{
  width:100%;
  position:relative;
  .cont_tak{
    position:absolute;
    top:0;
    right:0;
    width:4.48rem;
    height:3.18rem;
    background:url("../../assets/images/welcome_bg.png") no-repeat;
    padding:0.23rem 0.19rem 0.33rem 0.25rem;
    font-size:0.21rem;
    line-height:0.3rem;
    .txt{
      margin-bottom:0.15rem;
    }
  }

  .cont_icon{
    position:absolute;
    left:0;
    top:1.9rem;
  }

  .welcome_lang{
    position:relative;
    top:4.3rem;
    border-radius:0.38rem;
    border:0.03rem solid $main-color;
    padding:0.21rem 0.36rem 0.08rem;
    background:#ffffff;
    .lang_tit{
      font-size:0.26rem;
      color:$main-color;
      margin-bottom:0.16rem;
    }
    .lang_nr{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: wrap;
      .weg{
        width:3.05rem;
        height:1.04rem;
        line-height: 1.04rem;
        text-align: center;
        border-radius:0.1rem;
        border:0.02rem solid $main-gray;
        font-size:0.26rem;
        font-weight: bold;
        color:$main-gray;
        margin-bottom:0.16rem;
        text-transform: uppercase;
        &:active{
          background:$main-gray;
          border:0.02rem solid $main-gray;
          color:#ffffff;
        }
      }
    }
  }
}
