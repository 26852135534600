@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?3w561n');
  src:  url('fonts/icomoon.eot?3w561n#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?3w561n') format('truetype'),
    url('fonts/icomoon.woff?3w561n') format('woff'),
    url('fonts/icomoon.svg?3w561n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zoom:before {
  content: "\e91a";
}
.icon-location:before {
  content: "\e919";
}
.icon-phone-b:before {
  content: "\e918";
}
.icon-phone:before {
  content: "\e917";
}
.icon-running-person:before {
  content: "\e916";
}
.icon-backspace:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e914";
}
.icon-elevator:before {
  content: "\e913";
}
.icon-taxi:before {
  content: "\e912";
}
.icon-Dollar:before {
  content: "\e911";
}
.icon-escalator:before {
  content: "\e910";
}
.icon-wheelchair:before {
  content: "\e90f";
}
.icon-baby:before {
  content: "\e90e";
}
.icon-toilet:before {
  content: "\e90d";
}
.icon-circle:before {
  content: "\e90c";
}
.icon-health:before {
  content: "\e90b";
}
.icon-reader:before {
  content: "\e90a";
}
.icon-computer:before {
  content: "\e909";
}
.icon-fork:before {
  content: "\e908";
}
.icon-set:before {
  content: "\e907";
}
.icon-child:before {
  content: "\e906";
}
.icon-cabinet:before {
  content: "\e905";
}
.icon-music:before {
  content: "\e904";
}
.icon-leaf:before {
  content: "\e901";
}
.icon-running:before {
  content: "\e900";
}
.icon-all:before {
  content: "\e902";
}
.icon-shirt:before {
  content: "\e903";
}
